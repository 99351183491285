import { Controller } from "@hotwired/stimulus"

class VenueCheckoutController extends Controller {
  static targets = ["confirm"];

  moveToCheckout(event) {
    event.preventDefault();

    const url = event.target.dataset.redirectUrl;
    window.location.replace(url); 
  }
}

export default VenueCheckoutController;
